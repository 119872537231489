<template>
  <div class="filter-item col">
    <label>Price</label>
    <div class="layout">
      <button
        type="button"
        v-for="(item, key) in orientations"
        :class="{ active: item.toLowerCase() == active }"
        :key="key"
        :aria-label="`${item.toLowerCase()} orientation type`"
        @click="$emit('changeActive', 'priceRange', item.toLowerCase())"
      >
        <span class="status"></span> {{ item }}
        <font-awesome-icon
          v-if="item == 'Horizontal'"
          :icon="['fas', 'fa-left-right']"
        />
        <font-awesome-icon
          v-if="item == 'Vertical'"
          :icon="['fas', 'fa-up-down']"
        />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "PriceRangeFilter",
  props: {
    active: String,
  },
  data() {
    return {
      orientations: ["All", "< 0.001 BTC", "< 0.01 BTC", "< 0.1 BTC", "< 1 BTC", "< 10 BTC"]
    };
  },
};
</script>

<style lang="scss" scoped></style>
