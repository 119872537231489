<template>
  <div class="filter-area px-2 col">
    <h3>Filter</h3>

    <InscriptionRangeFilter 
      @changeActive="changeActive" 
      :active="insRange.value" 
    />
    <PriceRangeFilter
      @changeActive="changeActive"
      :active="priceRange.value"
    />
    <ContentTypeFilter 
      @changeActive="changeActive" 
      :active="contentType.value" 
    />
    <!--SafeSearchFilter @changeActive="changeActive" :active="safesearch.value" /-->

    <div class="flex row mt-1">
      <!--button
        type="button"
        aria-label="Filter"
        class="apply-btn"
        @click="filter"
      >
        Apply
      </button>
      <button type="button" aria-label="rest" class="reset" @click="reset">
        Reset
      </button-->
    </div>
  </div>
</template>

<script>
import InscriptionRangeFilter from "@/components/UI/Widget/Filter/InscriptionRangeFilter.vue";
import PriceRangeFilter from "@/components/UI/Widget/Filter/PriceRangeFilter.vue";
import ContentTypeFilter from "@/components/UI/Widget/Filter/ContentTypeFilter.vue";
//import SafeSearchFilter from "@/components/UI/Widget/Filter/SafeSearch.vue";

const defaultProps = {
  insRange: { type: "insRange", value: "all" },
  priceRange: { type: "priceRange", value: "all" },
  contentType: { type: "contentType", value: "all" },
  //safesearch: { type: "safesearch", value: "true" },
  page: { type: "page", value: 1 }
};

export default {
  name: "FilterWidget",
  components: {
    InscriptionRangeFilter,
    PriceRangeFilter,
    ContentTypeFilter,
    //SafeSearchFilter,
  },
  data() {
    return {
      ...defaultProps,
    };
  },
  methods: {
    filter() {
      console.log("this.insRange:", this.insRange)
      this.$store.dispatch("filter", [
        this.insRange,
        this.priceRange,
        this.contentType,
        //this.safesearch,
        this.page
      ]);
    },
    reset() {
      this.insRange = { type: "insRange", value: "all" };
      this.priceRange = { type: "priceRange", value: "all" };
      this.orderDateRange = { type: "orderDateRange", value: "all" };
      this.safesearch = { type: "safesearch", value: "true" };
      this.filter();
    },
    changeActive(type, active, range) {
      console.log("range", range)
      this[type] = { ...this[type], value: active, range: range };
      this.scrollToTop();
      this.filter();
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss">
.filter-area {
  h3 {
    color: var(--logo-color);
    font-weight: 500;
    font-size: 1.1rem;
    margin-bottom: 0;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--second-background-color);
  }
  .filter-item {
    flex: 0;
    padding: 1rem 0;
    border-bottom: 1px solid var(--second-background-color);
    label {
      margin-bottom: 10px;
      padding: 0;
      font-size: 13px;
    }
    .layout {
      display: flex;
      flex-wrap: wrap;
      .row {
        flex-wrap: wrap;
      }
      button {
        margin-right: 7px;
        margin-bottom: 7px;
        padding: 6px 1rem;
        font-size: 13px;
        font-weight: 500;
        display: inline-flex;
        align-items: center;
        transition: all 0.1s ease;
        &:hover {
          background-color: var(--four-background-color);
          .status {
            background-color: var(--logo-color);
          }
        }
        .status {
          background-color: var(--four-background-color);
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin-right: 7px;
          box-shadow: 0 0 0 -0 transparent;
          transition: all 0.2s ease;
        }
        &.active {
          .status {
            background-color: var(--green-color);
            box-shadow: 0 0 9px -1px var(--green-light-color);
          }
        }
      }
    }
    .color-layout {
      display: flex;
      flex-direction: column;
      .color-picker {
        padding: 0 !important;
        background: transparent;
        width: 1.5rem;
        margin-right: 1rem !important;
        margin-bottom: 1rem;
        height: 1.5rem;
        border-radius: 50%;
        outline: 1px solid var(--four-background-color);
        border: 2px solid var(--main-background-color);
        &.active {
          outline: 2px solid var(--logo-color);
        }
        &.red {
          background: red;
        }
        &.green {
          background: green;
        }
        &.yellow {
          background: yellow;
        }
        &.white {
          background: white;
        }
        &.pink {
          background: pink;
        }
        &.gray {
          background: gray;
        }
        &.orange {
          background: orange;
        }
        &.turquoise {
          background: turquoise;
        }
        &.blue {
          background: blue;
        }
        &.lilac {
          background: #c8a2c8;
        }
        &.black {
          background: #222;
        }
        &.brown {
          background: brown;
        }
      }
      .colors {
        width: 80%;
        margin-top: 10px;
        flex-wrap: wrap;
      }
    }
  }
  .apply-btn {
    flex: 2;
    background-color: var(--blue-color);
    font-size: 14px;
    padding: 1rem;
    color: white;
    &:hover {
      background-color: var(--blue-dark-color);
    }
  }
  .reset {
    flex: 1;
    background-color: transparent;
    font-size: 14px;
    padding: 1rem;
  }
}
</style>
