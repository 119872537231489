<template>
  <Layout sidebar footer header>
    <div class="row">
      <div class="col flex-1 py-1 md-pl-1 mb-5">
        <TimelineHeader />

        <Loading v-show="!load" />

        <template v-if="load">
          <div 
            v-if="(!getSearchTextFromStorage || getSearchTextFromStorage == '')"
            class="flex-container"
          >
            <Loading v-show="!load" noHeight small />
            <div
              class="cover"
              v-for="item in getPhotosFromStorage"
              :key="Object.values(item).ins_id"
              v-show="load"
            >
              <Card
                @showBuyModal="showBuyModal" 
                @showDetailModal="showDetailModal" 
                :data="item"
              />
            </div>
          </div>
          <div 
            v-if="getSearchTextFromStorage && getSearchTextFromStorage != ''"
            class="flex-container-search"
          >
            <Loading v-show="!load" noHeight small />
            <div
              class="cover"
              v-for="item in getPhotosFromStorage"
              :key="Object.values(item).ins_id"
              v-show="load"
            >
              <SearchCard
                @showBuyModal="showBuyModal" 
                @showDetailModal="showDetailModal" 
                :data="item"
              />
            </div>
          </div>
          <!-- infinite scroll loading -->
          <span v-show="$store.getters.getFetchStatus" class="infinite-status">
            <font-awesome-icon
              class="loading-spinner"
              :icon="['fas', 'fa-spinner']"
              size="lg"
            />Loading...
          </span>
        </template>

        <InfoAlert
          v-if="load & (getPhotosFromStorage == 'not-found')"
          text="Ups... 😪 We couldn't find what you're looking for."
        />
      </div>

      <!--Albums :albums="getAlbumsFromStorage" /-->

      <SingleModal 
        v-if="detailModalFlag" 
        :insId="selectedInscriptionId"
        :insNum="selectedInscriptionNum"
        :contentType="selectedContentType"
        @close="detailModalFlag = false"/>

      <BuyModal 
        v-if="buyModalFlag" 
        :orderId="selectedOrderId" 
        @close="buyModalFlag = false"/>

    </div>
  </Layout>
</template>

<script>
import Layout from "@/components/Layout.vue";
import Card from "@/components/UI/Card/Card.vue";
import SearchCard from "@/components/UI/Card/SearchCard.vue";
import Loading from "@/components/UI/Loading.vue";
//import Albums from "@/components/UI/Widget/Albums.vue";
import TimelineHeader from "@/components/UI/TimelineHeader.vue";
import InfoAlert from "@/components/UI/Alert/Info.vue";
import SingleModal from "@/components/SingleModal.vue";
import BuyModal from "@/components/BuyModal.vue";

import { nextTick } from "vue";

export default {
  components: {
    Layout,
    Card,
    Loading,
    SearchCard,
    TimelineHeader,
    InfoAlert,
    SingleModal,
    BuyModal
  },
  data() {
    return {
      load: false,
      detailModalFlag: false,
      buyModalFlag: false,
      selectedInscriptionId: null,
      selectedOrderId: null,
      selectedContentType: null,
      selectedInscriptionNum: null
    };
  },
  async created() {
    await this.$store.dispatch("fetchAll");
  },
  methods: {
    async fetchMore() {
      await this.$store.dispatch("fetchMore");
    },
    showBuyModal(orderId) {
      this.buyModalFlag = true
      this.selectedOrderId = orderId
    },
    showDetailModal(insId, insNum, contentType) {
      this.detailModalFlag = true
      this.selectedInscriptionId = insId
      this.selectedInscriptionNum = insNum
      this.selectedContentType = contentType
      console.log("showDetailModal called:", insNum, insId, contentType)
    }
  },
  computed: {
    getPhotosFromStorage() {
      return this.$store.getters.getPhotosTimeline
    },
    getAlbumsFromStorage() {
      return this.$store.getters.getAlbums
    },
    getSearchTextFromStorage () {
      return this.$store.getters.getSearchText
    }
  },
  async updated() {
    await nextTick();
    if (this.load == true) {
      setTimeout(() => {
        window.onscroll = () => {
          const div = document.querySelector(".content-inner");
          if (div && (window.innerHeight + window.scrollY >= div.offsetHeight)) {
            if (this.getSearchTextFromStorage == null) {
              this.fetchMore()
            }
          }
        };
      }, 1500);
    }
  },
  watch: {
    async getPhotosFromStorage(last) {
      if (last == null || last == []) {
        this.load = false;
        if (this.getSearchTextFromStorage == "") {
          this.$store.dispatch("fetchLatest");
          return;
        }
      } else {
        this.load = true;
      }
      setTimeout(() => {
        this.load = true;
      }, 700);
    },
  },
  beforeRouteEnter(to, from, next) {
    console.log("to", to, "from", from)
    switch (to.name) {
      case "priciest":
        next((vm) => {
          if(!vm.$store.getters.getSearchText) {
            vm.$store.dispatch("fetchHighest");
          }
        });
        break;
      case "cheapest":
        next((vm) => {
          if(!vm.$store.getters.getSearchText) {
            vm.$store.dispatch("fetchLowest");
          }
        });
        break;
      case "rarest":
        next((vm) => {
          if(!vm.$store.getters.getSearchText) {
            vm.$store.dispatch("fetchRarest");
          }
        });
        break;
      case "latest":
        next((vm) => {
          if(!vm.$store.getters.getSearchText) {
            vm.$store.dispatch("fetchLatest");
          }
        });
        break;
      case "home":
        next((vm) => {
          if(!vm.$store.getters.getSearchText) {
            vm.$store.dispatch("fetchLatest");
          }
        });
        break;
      case "photo":
        next((vm) => {
          vm.$store.commit("toggleBackdrop");
        });
        break;
      default:
        next();
        break;
    }
  },
  beforeRouteLeave(to, from, next) {
    if (from.name == "photo") {
      this.$store.commit("toggleBackdrop");
      next();
    }
    next();
  },
};
</script>

<style lang="scss" scoped>
.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.flex-container > div {
  width: 212px;
  margin: 10px;
  line-height: 25px;
  /*image-rendering: pixelated;*/
}
.flex-container-search > div {
  width: 312px;
  margin: 10px;
  line-height: 25px;
  align-self: center;
}
.mb-5 {
  margin-bottom: 5rem;
}
.infinite-status {
  display: flex;
  justify-content: center;
  padding: 2rem;
  font-size: 1rem;
  align-items: center;
  svg {
    margin-right: 1rem;
  }
}

</style>
