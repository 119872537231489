import Axios from "axios"

const bitcoinPriceApiUrl = "https://blockchain.info/ticker?cors=true"

let urlParameters = {
  q: "",
  insRange: "all",
  priceRange: "all",
  contentType: "all",
  category: null,
  orderDateRange: "all",
  safesearch: "true",
  order: "popular",
  page: 1,
  perPage: 60
}

let totalPages

function  btcToSat(btc) {
  return Math.floor(Number(btc) * Math.pow(10, 8))
}

const rangeCalcutalor = (rangeStr) => {

  return Number(rangeStr
    .replace("< ", "")
    .replace("k", "000")
    .replace("m", "000000")
    .replace("btc", "")
    .replace("all", "0")
    .replace(" ", "")
  )
}

const urlGenerator = (newUrl) => {
  newUrl.forEach((item) => {
    const { type, value } = item;
    urlParameters = {
      ...urlParameters,
      [type]: value,
    }
  })

  let URL =
    `${process.env.VUE_APP_SELLORDER_URL}?sort_by=${urlParameters.sortBy}` +
    `&sort=${urlParameters.sort}&page=${urlParameters.page}` +
    `&per_page=${urlParameters.perPage}` +
    `&content_type=${urlParameters.contentType}` +
    `&ins_max=${rangeCalcutalor(urlParameters.insRange)}` +
    `&price_max=${btcToSat(rangeCalcutalor(urlParameters.priceRange))}`

    console.log("urlParameters", urlParameters)

  return URL
}

function hasOnlyDigits(value) {
    return /^-?\d+$/.test(value)
}
/*
async function getInscriptionById (id) {
  console.log("Fetching data for inscription id:", id)

  Axios.get(process.env.VUE_APP_INSCRIPTION_ID_URL + id)
    .then(response => {
      
      const data = [...response.data.matchAll(/<dt>(.*?)<\/dt>\s*<dd.*?>(.*?)<\/dd>/gm)]
        .map(x => { x[2] = x[2].replace(/<.*?>/gm, ''); return x })
        .reduce((a, b) => { return { ...a, [b[1]]: b[2] } }, {});
        console.log("ins-data:", data)

      const insNum = Number(response.data.match(/<h1>(.*?)<\/h1>/gm).replace("Inscription "))

        const insData = [{
          content_type: data["content type"],
          ins_id: data["id"],
          ins_num: insNum,
          utxo: data["output"],
          location: data["location"],
          address: data["address"],
          timestamp: data["timestamp"],
          price_sat: 0
        }]
        console.log("insData", insData)
        return insData
    })
}
*/

export default {
  async fetchAll({ getters, commit }) {
    console.log("getPhotosTimeline.length: ", getters.getPhotosTimeline.length)
    if(getters.getPhotosTimeline.length == 0) {


      commit("setPhotosTimeline", null)
      try {
        //const albums = [];
        await Promise.all([
          /*getters.getCategories.forEach((element) => {
            Axios.get(
              `${urlGenerator([
                { type: "perPage", value: 4 },
              ])}&category=${element}`
            ).then(({ data }) => {
              const { docs } = data;
              const album = [];
              docs.forEach((item) =>
                album.push({ [element]: { ...item, albumTitle: element } })
              );
              albums.push(album);
              commit("setAlbums", albums);
            });
          }),*/
          Axios.get(
            urlGenerator([
              { type: "sortBy", value: "created_at" },
              { type: "sort", value: "desc" }
            ])
          ).then(({ data }) => {
            commit("setPhotosTimeline", data.docs);
            totalPages = data.totalPages
          }),
          Axios.get(bitcoinPriceApiUrl).then(({ data }) => {
            commit("setBTCPrice", data.USD.last);
          }),
        ]);
      } catch (error) {
        console.log(error);
      }
    }
  },

  async fetchHighest({ commit }) {
    commit("setPhotosTimeline", null);
    try {
      await Promise.all([
        Axios.get(
          urlGenerator([
            { type: "sortBy", value: "price" },
            { type: "sort", value: "desc" },
            { type: "page", value: 1 },
          ])
        ).then(({ data }) => {
          commit("setPhotosTimeline", data.docs);
          totalPages = data.totalPages
        }),
      ]);
    } catch (error) {
      console.log(error);
    }
  },

  async fetchLowest({ commit }) {
    commit("setPhotosTimeline", null);
    try {
      await Promise.all([
        Axios.get(
          urlGenerator([
            { type: "sortBy", value: "price" },
            { type: "sort", value: "asc" },
            { type: "page", value: 1 },
          ])
        ).then(({ data }) => {
          commit("setPhotosTimeline", data.docs);
          totalPages = data.totalPages
        }),
      ]);
    } catch (error) {
      console.log(error);
    }
  },

  async fetchLatest({ commit }) {
    commit("setPhotosTimeline", null);
    try {
      await Promise.all([
        Axios.get(
          urlGenerator([
            { type: "sortBy", value: "created_at" },
            { type: "sort", value: "desc" },
            { type: "page", value: 1 },
          ])
        ).then(({ data }) => {
          commit("setPhotosTimeline", data.docs);
          totalPages = data.totalPages
        }),
      ]);
    } catch (error) {
      console.log(error);
    }
  },

  async fetchRarest({ commit }) {
    commit("setPhotosTimeline", null);
    try {
      await Promise.all([
        Axios.get(
          urlGenerator([
            { type: "sortBy", value: "insc_num" },
            { type: "sort", value: "asc" },
            { type: "page", value: 1 },
          ])
        ).then(({ data }) => {
          commit("setPhotosTimeline", data.docs);
          totalPages = data.totalPages
        }),
      ]);
    } catch (error) {
      console.log(error);
    }
  },

  async fetchOne({ commit }, payload) {

    commit("setFetchStatus", true)
    commit("setSellOrder", null)

    try {
      await Axios.get(process.env.VUE_APP_SELLORDER_URL + "/" + payload)
      .then(response => {
        commit("setSellOrder", response.data)
        commit("setFetchStatus", false)
      })
      Axios.get(bitcoinPriceApiUrl).then(({ data }) => {
          commit("setBTCPrice", data.USD.last);
      })
    } catch (error) {
      commit("setFetchStatus", false)
      console.log(error);
    }

    commit("resetBuyForm")
    commit("setShowDummyPsbtQR", false)
    commit("setShowBuyPsbtQR", false)

  },

  async search({ commit, dispatch }, payload) {
    console.log("search: ", payload)
    commit("setSearchText", payload)
    if(payload.trim() == "" && !(hasOnlyDigits(payload) || (payload.length == 66))) {
      dispatch("destroySearching")
      console.log("SEARCH NOT VALID:", payload)
      return
    }

    if(hasOnlyDigits(payload) || (payload.length == 66)) {

      commit("setFetchStatus", true)
      commit("setPhotosTimeline", null)

      try {

        await Axios.get(process.env.VUE_APP_SELLORDER_URL + "/" + payload)
        .then(response => {
          if(response.data.length > 0) {
            commit("setPhotosTimeline", response.data)
            commit("setFetchStatus", false)
          } else if (hasOnlyDigits(payload)) {
            console.log(`INS# NOT FOUND... Searching for ${payload} on ordinals.com`)
            Axios.get(process.env.VUE_APP_INSCRIPTION_NUMBER_URL + payload)
            .then(response => {

              const insId = response.data.match(/\/inscription\/(.*?)>/)[1]
              Axios.get(process.env.VUE_APP_INSCRIPTION_ID_URL + insId)
              .then(response => {
                
                const data = [...response.data.matchAll(/<dt>(.*?)<\/dt>\s*<dd.*?>(.*?)<\/dd>/gm)]
                  .map(x => { x[2] = x[2].replace(/<.*?>/gm, ''); return x })
                  .reduce((a, b) => { return { ...a, [b[1]]: b[2] } }, {});
                  console.log("ins-data:", data)

                  const insData = [{
                    content_type: data["content type"],
                    ins_id: data["id"],
                    ins_num: payload,
                    type: 'buy',
                    utxo: data["output"],
                    location: data["location"],
                    address: data["address"],
                    timestamp: data["timestamp"],
                    price_sat: 0
                  }]
                  commit("setPhotosTimeline", insData)
                  console.log("insData", insData)

                  commit("setFetchStatus", false)
                  
              })
              
            })
          } else if(payload.length == 66) {
            console.log(`INS_ID NOT FOUND... Searching for ${payload} on ordinals.com`)
            Axios.get(process.env.VUE_APP_INSCRIPTION_ID_URL + payload)
              .then(response => {
                
                const data = [...response.data.matchAll(/<dt>(.*?)<\/dt>\s*<dd.*?>(.*?)<\/dd>/gm)]
                  .map(x => { x[2] = x[2].replace(/<.*?>/gm, ''); return x })
                  .reduce((a, b) => { return { ...a, [b[1]]: b[2] } }, {});

                  data["number"] = Number(response.data.match(/<h1>Inscription (.*?)<\/h1>/)[1])
                  
                  console.log("ins-data:", data)

                  const insData = [{
                    content_type: data["content type"],
                    ins_id: data["id"],
                    ins_num: data["number"],
                    type: 'buy',
                    utxo: data["output"],
                    location: data["location"],
                    address: data["address"],
                    timestamp: data["timestamp"],
                    price_sat: 0
                  }]
                  commit("setPhotosTimeline", insData)
                  console.log("insData", insData)

                  commit("setFetchStatus", false)
                  
              })
          }

          Axios.get(bitcoinPriceApiUrl).then(({ data }) => {
              commit("setBTCPrice", data.USD.last);
          })
        })

      } catch (error) {
        commit("setFetchStatus", false)
        dispatch("destroySearching")
        console.log(error)
      }
    } else {
      commit("setFetchStatus", false)
      dispatch("destroySearching")
      //commit("setSearchText", payload)
    }
  },

  async fetchInscriptionByNumber({ commit }, payload) { 
    if (hasOnlyDigits(payload.ins_num)) {
    
    await Axios.get(process.env.VUE_APP_INSCRIPTION_NUMBER_URL + payload.ins_num)
      .then(response => {
        const insId =  response.data.match(/\/inscription\/(.*?)>/)[1]
        //commit("setInscriptions", null)
        Axios.get(process.env.VUE_APP_INSCRIPTION_ID_URL + insId)
          .then(response => {
            
            const data = [...response.data.matchAll(/<dt>(.*?)<\/dt>\s*<dd.*?>(.*?)<\/dd>/gm)]
              .map(x => { x[2] = x[2].replace(/<.*?>/gm, ''); return x })
              .reduce((a, b) => { return { ...a, [b[1]]: b[2] } }, {});
              console.log("ins-data:", data)

            data["number"] = Number(response.data.match(/<h1>Inscription (.*?)<\/h1>/)[1])

            const insData = {
              content_type: data["content type"],
              ins_id: data["id"],
              ins_num: data["number"],
              utxo: data["output"],
              location: data["location"],
              address: data["address"],
              timestamp: data["timestamp"],
              price_sat: 0,
              is_on_sale: payload.is_on_sale
            }
            commit("setInscription", insData)
          })
      })
    }
  },

  async fetchSell({ commit, dispatch }, payload) {
    console.log("fetchSell called with payload:", payload)
    commit("setFetchStatus", true)
    commit("setSellOrder", null)
    commit("setShowSellPsbtQR", false)
    commit("setInscription", null)

    if(hasOnlyDigits(payload) && !(payload == "")) {
      try {
        const payloadData = {
          ins_num: payload,
          is_on_sale: true
        }
        await Axios.get(process.env.VUE_APP_SELLORDER_URL + "/" + payload)
        .then(response => {
          if(response.data.length > 0) {
            commit("setSellOrder", response.data)
            const insData = {
              content_type: response.data[0].content_type,
              ins_id: response.data[0].ins_id,
              ins_num: response.data[0].ins_num,
              is_on_sale: true
            }
            commit("setInscription", insData)

            dispatch("fetchInscriptionByNumber", payloadData)
            
          } else {
            console.log(`NOT FOUND... Searching for ${payload} on ordinals.com`)
            payloadData.is_on_sale = false
            dispatch("fetchInscriptionByNumber", payloadData)
            //commit("setSellOrder", insData)
            //console.log("insData", insData)
          }
          commit("setFetchStatus", false)
        })

        Axios.get(bitcoinPriceApiUrl).then(({ data }) => {
          commit("setBTCPrice", data.USD.last);
        })

      } catch (error) {
        commit("setFetchStatus", false)
        console.log(error)
      }
    } else {
      commit("setFetchStatus", false)
      console.log(`Unable to fetchSell. ${payload} not a valid number`)
    }
  },

  async filter({ commit }, payload) {
    commit("setPhotosTimeline", null);
    try {
      await Promise.all([
        Axios.get(urlGenerator(payload)).then(({ data }) => {
          if (data.docs) {
            if (data.docs.length > 0) {
              commit("setPhotosTimeline", data.docs);
              totalPages = data.totalPages
            } else commit("setPhotosTimeline", null);
          }
        }),
      ]);
    } catch (error) {
      console.log(error);
    }
  },

  async fetchMore({ commit }) {
    if (urlParameters.page < totalPages) {
      commit("setFetchStatus", true);
      try {
        await Promise.all([
          Axios.get(
            urlGenerator([
                { type: "page", value: urlParameters.page + 1 }
              ])
          ).then(({ data }) => {
            if (data.docs) {
              if (data.docs.length > 0) {
                commit("morePhotosTimeline", data.docs);
                totalPages = data.totalPages
              }
            }
          }),
        ]);
      } catch (error) {
        console.log(error);
        commit("setFetchStatus", false);
      }
      commit("setFetchStatus", false);
    } else {
      commit("setFetchStatus", false);
    }
  },
  destroySearching({ commit, dispatch }) {
    commit("setSearchText", null);
    //dispatch("filter", [])
    dispatch("filter", [{ type: "page", value: 1 }]);
  },
  resetCategory() {
    urlParameters.category = null;
  },
  setdarkmode({ commit }, payload) {
    commit("darkmode", payload)
  }
};
