<template>
  <div class="card">

    <router-link :to="{ name: data.type=='sell' ? 'buy' : 'sell', params: { id: data.ins_num }}">
      <div class="thumbnail">

        <img v-if="isImage(contentType)"
        :src="'https://ordinals.com/content/' + data.ins_id" class="img" loading="lazy"/>

        <div 
          v-if="isIframe(contentType)"
          class="txt">

          <iframe class="frame" sandbox=allow-scripts scrolling=no loading=lazy :src='"https://ordinals.com/preview/" + data.ins_id'>
          </iframe>

        </div>
      </div>
      <label class="ins-num">{{"#" + data.ins_num}}</label>
    </router-link>
    
    <h3 v-if="data.type=='sell'" class="ins-price-btc">{{ satToBtc(data.price_sat) }} BTC
      <label class="ins-price-usd"> ${{(satToBtc(data.price_sat) * getBTCPrice).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
      })}}
      </label>
    </h3>

    <h3 v-if="data.type=='buy'" class="ins-price-btc">Are you the <u>Owner</u>?</h3>

    <router-link v-if="data.type=='buy'" :to="{ name: 'sell', params: { id: data.ins_num }}">
      <transition name="fade" mode="out-in">
      <strong
        type="button"
        aria-label="Sell Now"
        style="background-color:var(--blue-color);"
        class="submit-btn"
        @click="handleButtonClick"
      >
        Sell Now
      </strong>
    </transition>
    </router-link>

    <router-link v-if="data.type=='sell'" :to="{ name: 'buy', params: { id: data.ins_num }}">
      <!--transition name="fade" mode="out-in"-->
      <strong
        type="button"
        aria-label="Buy Now"
        class="submit-btn"
        @click="/*handleButtonClick*/"
      >
        Buy Now
      </strong>
    <!--/transition-->
    </router-link>
    
    <router-link v-if="data.type=='sell'" :to="{ name: 'sell', params: { id: data.ins_num }}">
      <!--transition name="fade" mode="out-in"-->
      <p></p>
      <strong
        type="button"
        aria-label="Update Price"
        class="submit-btn"
        style="background-color:var(--third-background-color);"
      >
        Update Sell Order
      </strong>
    <!--/transition-->
    </router-link>
    
  </div>
</template>

<script>
import axios from "axios";


export default {
  name: "SearchCardComponent",
  props: {
    data: [] || null,
  },
  data() {
    return {
      load: false,
      contentType: "",
      textContent: "",
      htmlContent: "",

    };
  },
  methods: {
    substrTitle(title) {
      return title.substr(0, 20) + "...";
    },
    satToBtc(sat) {
      return Number(sat) / Math.pow(10, 8)
    },
    btcToSat(btc) {
      return Math.floor(Number(btc) * Math.pow(10, 8))
    },
    isImage(contentType) {
      return (
        contentType.includes('image/png') || 
        contentType.includes('image/webp') || 
        contentType.includes('image/jpeg') || 
        contentType.includes('image/gif') || 
        contentType.includes('image/svg+xml') || 
        contentType.includes('image/avif')
      )
    },
    isIframe(contentType) {
      return !(
        contentType.includes('image/png') || 
        contentType.includes('image/webp') || 
        contentType.includes('image/jpeg') || 
        contentType.includes('image/gif') || 
        contentType.includes('image/svg+xml') || 
        contentType.includes('image/avif')
      )
      /*return (
        contentType.includes('text/html') || 
        contentType.includes('video/mp4') ||
        contentType.includes('video/webm') ||
        contentType.includes('audio/mpeg') || 
        contentType.includes('application/pdf') || 
        contentType.includes('text/json') || 
        contentType.includes('text/plain') || 
        contentType.includes('application/json')
      )*/
    },
    handleButtonClick() {
      //this.$store.dispatch("destroySearching")
    },
    getContentType() {
      axios.head("https://ordinals.com/content/" + this.data.ins_id)
      .then(response => {
        //console.log(this.data.ins_num, this.data.ins_id, response.headers.getContentType());
        this.contentType = response.headers.getContentType();
        //if(this.contentType.includes('text/json') || this.contentType.includes('text/plain')) {
        //  this.getTextContent()
        //}
      })
    },
    getTextContent() {
      axios.get("https://ordinals.com/content/" + this.data.ins_id)
      .then(response => {
        this.textContent = response.data;
      })
      /*return await axios.get("https://ordinals.com/content/" + this.data.ins_id)
      .then(response => response.data)*/
    },
    getHTMLContent() {
      axios.get("https://ordinals.com/content/" + this.data.ins_id)
      .then(response => {
        //console.log(response.headers.getContentType());
        this.htmlContent = response.data;
      })
    }
  },
  computed: {
    getTags() {
      return this.data.tags.split(",");
    },
    getText() {
      /*
      if (typeof this.textContent === "string" || this.textContent instanceof String) {
        console.log("String:", this.textContent)
        return this.textContent.trim()
      } else {
        console.log("JSON:", this.textContent)
        return JSON.stringify(this.textContent).trim()
      }*/
      // DISABLED GETTING TEXT FOR NOW @mounted()
      return this.textContent
    },
    getBTCPrice() {
      return this.$store.getters.getBTCPrice;
    }
  },
  /*render() {
    console.log(this.$refs.preTxt)     
  },*/
  mounted() {
    setTimeout(() => {
      this.load = true;
    }, 700);
    this.getContentType();
    
    /*if (this.contentType.includes('text/json') || this.contentType.includes('text/plain')) {
      this.getTextContent();
      console.log("getTextContent:", this.textContent)
    }
    if (this.contentType.includes('text/html')) {
      this.getHTMLContent();
    }*/
    //console.log("document:", document)
    
    //const target = document.querySelector(".pre-txt");
    //observer.observe(target);

    //const preTxt = document.querySelector("pre-txt")
    //console.log("preTxt:", preTxt)

    //console.log("this.$refs.preTxt:", this.$refs.preTxt)
    
    
  },
};
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  background: var(--second-background-color);
  padding: 6px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  /*box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;*/
  /*box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;*/
  animation: fromTopWithFade 0.3s ease;
  .submit-btn {
    display: block;
  }
  &:hover {
      .img {
        filter: saturate(1.6);
        transition: all 0.3s ease;
        transform: scale(1.2) /*translate(10px, 10px);*/
        /*position:absolute; left:50px; transform: scale(1.3)*/
      }
      .txt {
        filter: saturate(1.6);
        transition: all 0.3s ease;
        transform: scale(1.2) /*translate(10px, 10px);*/
        /*position:absolute; left:50px; transform: scale(1.3)*/
      }
      .price-usd {
        display: none;
      }
      .submit-btn {
        display: block;
        background-color: var(--green-color);
        /*background-image: linear-gradient(to right, #159005, #080808, #080808);*/
        box-shadow: 0 2px 12px -8px #000;
        background-position: 0;
      }
      .sell-btn {
        display: block;
        background-color: var(--green-color);
        /*background-image: linear-gradient(to right, #159005, #080808, #080808);*/
        box-shadow: 0 2px 12px -8px #000;
        background-position: 0;
      }
    }
  .header-label {
    align-content: center;
  }
  .thumbnail {
    border-radius: 5px;
    overflow: hidden;
    background-color: var(--third-background-color);
    /*animation: backgroundChange 1.8s linear infinite;*/
    &:hover {
      .img {
        cursor: pointer;
        filter: saturate(1.6);
        transition: all 0.3s ease;
        transform: scale(1.2) /*translate(10px, 10px);*/
        /*position:absolute; left:50px; transform: scale(1.3)*/
      }
      .txt {
        filter: saturate(1.6);
        transition: all 0.3s ease;
        transform: scale(1.2) /*translate(10px, 10px);*/
        /*position:absolute; left:50px; transform: scale(1.3)*/
      }
    }
    .img {
      object-fit: contain;
      min-width: 300px;
      max-width: 300px;
      align-self: center;
      border-radius: 5px;
      filter: saturate(1);
      transition: all 0.3s ease;
      display: flex;
      min-height: 300px;
      max-height: 300px;
    }
    .txt {
      width: 300px;
      height: 300px;
      border-radius: 5px;
      filter: saturate(1);
      transition: all 0.3s ease;
      display: flex;
      min-height: 300px;
      background-color: var(--main-background-color);
      font-size: min(1.4vw, 1.4vh); 
      opacity: 1;
      margin: 0;
    }
    .frame {
      width: 300px;
      height: 300px;
      min-height: 300px;
      max-height: 300px;
      border: 0;
    }
  }
  label {
    font-size: 13px;
    text-align: center;
    padding: 6px 0;
    padding-bottom: 0;
    font-weight: 500;
    .tag {
      &:not(:last-child) {
        &::after {
          content: ",";
        }
      }
      &:hover {
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-underline-offset: 2px;
      }
    }
  }
  .ins-num {
    cursor: auto;
    font-weight: bolder;
    font-size: 16px;
  }
  .ins-price-btc {
    color: var(--login-form-label-color);
  }
  .ins-price-usd {
    cursor: auto;
    padding: 0.5rem;
    font-size: 17px;
    color: var(--login-form-label-color);
  }
  .buy-btn {
    flex: 2;
    /*background-color: var(--gray-color);*/
    background-color: var(--button-background-color);
    font-size: 14px;
    padding: 1rem;
    color: white;
    &:hover {
      /*background-color: var(--blue-dark-color);*/
      background-color: var(--blue-color);
    }
  }
  .submit-btn {
    /*margin: 1.5rem 0;*/
    padding: 1rem;
    border-radius: 5px;
    text-align: center;
    /*background-image: linear-gradient(to right, #66d4e0, #9798e6, #e23ff5);*/
    /*background-image: linear-gradient(to right, #0f9b0f, #000000);*/
    /*color: white;*/
    background-color: var(--green-color);
    background-size: 300%;
    background-position: center;
    color: var(--button-color);
    font-weight: 600;
    text-shadow: 0 1px 1px #00000020;
    transition: all 0.4s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    color: white;
    &:hover {
      cursor: pointer;
      background-color: var(--green-color);
      border-radius: 30px;
      box-shadow: 0 2px 12px -8px #000;
      background-position: 0;
      /*color: white;*/
    }
    &:focus {
      box-shadow: none;
      transform: translateY(1px);
    }
  }
  .sell-btn {
    /*margin: 1.5rem 0;*/
    padding: 1rem;
    border-radius: 5px;
    text-align: center;
    /*background-image: linear-gradient(to right, #66d4e0, #9798e6, #e23ff5);*/
    /*background-image: linear-gradient(to right, #0f9b0f, #000000);*/
    /*color: white;*/
    background-color: var(--purple-color);
    background-size: 300%;
    background-position: center;
    color: var(--button-color);
    font-weight: 600;
    text-shadow: 0 1px 1px #00000020;
    transition: all 0.4s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    color: white;
    &:hover {
      cursor: pointer;
      background-color: var(--green-color);
      border-radius: 16px;
      box-shadow: 0 2px 12px -8px #000;
      background-position: 0;
      /*color: white;*/
    }
    &:focus {
      box-shadow: none;
      transform: translateY(1px);
    }
  }
  .card-footer {
    display: flex;
    /*margin: 10px;*/
    /*justify-content: center;*/
    .price {
       /*justify-content: flex-start;*/
       align-items: start;
    }
    .buy {
       /*justify-content: end;*/
       align-items: end;
    }
  }
  .card-info {
    display: flex;
    justify-content: center;
    li {
      list-style: none;
      padding: 0 5px;
      font-size: 13px;
    }
  }
}

@keyframes backgroundChange {
  0% {
    background: var(--main-background-color);
  }
  50% {
    background: var(--second-background-color);
  }
}
</style>
