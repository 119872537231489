import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueGtag from "vue-gtag";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faLock,
  faUser,
  faTextHeight,
  faLink,
  faImage,
  faBars,
  faImages,
  faSpinner,
  faThumbsUp,
  faComment,
  faUpDown,
  faLeftRight,
  faXmark,
  faRightFromBracket,
  faEllipsisVertical,
  faMoon,
  faSun,
  faFilter,
  faInbox
} from "@fortawesome/free-solid-svg-icons";
import { faSquare, faKeyboard } from "@fortawesome/free-regular-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faBtc } from "@fortawesome/free-brands-svg-icons";
import { faBitcoin } from "@fortawesome/free-brands-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(
  faInbox,
  faBitcoin,
  faBtc,
  faUser,
  faLock,
  faSquare,
  faTextHeight,
  faKeyboard,
  faLink,
  faImage,
  faBars,
  faImages,
  faSpinner,
  faThumbsUp,
  faComment,
  faUpDown,
  faLeftRight,
  faXmark,
  faRightFromBracket,
  faEllipsisVertical,
  faMoon,
  faSun,
  faFilter,
  faGithub
);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.use(VueGtag, {
  config: { id: "G-5QVSPSL7W4" }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
