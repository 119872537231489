import Vue from "vue"
import VueRouter from "vue-router"
import LoginView from "../views/LoginView.vue"
import BuyView from "../views/BuyView.vue"
import SellView from "../views/SellView.vue"
import HomeView from "../views/HomeView.vue"
import TagView from "../views/TagView.vue"
import CategoryView from "../views/CategoryView.vue"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "latest",
    component: HomeView,
  },
  {
    path: "/rarest",
    name: "rarest",
    component: HomeView,
  },
  {
    path: "/priciest",
    name: "priciest",
    component: HomeView,
  },
  {
    path: "/cheapest",
    name: "cheapest",
    component: HomeView,
  },
  {
    path: "/buy/:id",
    name: "buy",
    component: BuyView,
  },
  {
    path: "/sell/:id",
    name: "sell",
    component: SellView,
  },
  {
    path: "/photo/:id",
    name: "photo",
    component: HomeView,
  },
  {
    path: "/tag/:tag",
    name: "tag",
    component: TagView,
  },
  {
    path: "/category/:category",
    name: "category",
    component: CategoryView,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  // // clear search if exists
  // if (store.state.searchText != "") {
  //   store.state.searchText = "";
  // }
  next();
});

export default router;
