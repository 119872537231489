<template>
  <div class="filter-item col">
    <label>Inscription #</label>
    <div class="layout">
      <button
        type="button"
        :class="{ active: item.label.toLowerCase() == active }"
        v-for="(item, key) in types"
        :key="key"
        :aria-label="`${item.label.toLowerCase()} type`"
        @click="$emit('changeActive', 'insRange', item.label.toLowerCase(), item.range)"
      >
        <span class="status"></span> {{ item.label }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "InscriptionRangeFilter",
  data() {
    return {
      types: [ 
        { 
          label: "All",
          range: {
            from: 0,
            to: 0
          }
        },
        {
          label: "< 10K",
          range: {
            from: 0,
            to: 10000
          }
        },
        {
          label: "< 100K",
          range: {
            from: 0,
            to: 100000
          }
        },
        {
          label: "< 500K",
          range: {
            from: 0,
            to: 500000
          }
        },
        {
          label: "< 1M",
          range: {
            from: 0,
            to: 1000000
          }
        },
        {
          label: "< 2M",
          range: {
            from: 0,
            to: 2000000
          }
        },
        {
          label: "< 3M",
          range: {
            from: 0,
            to: 3000000
          }
        },
        {
          label: "< 4M",
          range: {
            from: 0,
            to: 4000000
          }
        }

      ],
    };
  },
  props: {
    active: String,
  },
};
</script>

<style lang="scss" scoped></style>
