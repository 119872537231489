<template>
  <div class="modal-mask" @click="$emit('close')">
  <div class="fixed p-2" v-if="photo" @click="$emit('donothing')">
    <div class="single-layout col center">
      <div class="image-post">
        <Loading v-show="!getImageLoad" single />
        <!--h2 class="heading">#{{ insNum }}</h2-->
        <!--header class="row" v-show="getImageLoad">
          <div class="photo-info row">
            <li>
              <font-awesome-icon :icon="['fas', 'fa-thumbs-up']" size="xs" />
              {{ photo.likes }}
            </li>
            <li>
              <font-awesome-icon :icon="['fas', 'fa-comment']" size="xs" />
              {{ photo.comments }}
            </li>
          </div>
        </header-->
        <img
          v-if="contentType.includes('image/png') || contentType.includes('image/webp') || contentType.includes('image/jpeg') || contentType.includes('image/png') || contentType.includes('image/gif') ||
          contentType.includes('image/svg+xml') || contentType.includes('image/avif')"
          :src="photo.largeImageURL"
          :alt="photo.tags"
          class="single-image"
          v-show="getImageLoad"
          @load="imageload = true"
        />
        <div 
          v-if="contentType.includes('text/html') || contentType.includes('text/plain') || contentType.includes('video/mp4') || contentType.includes('application/pdf') || contentType.includes('application/json')"
          class="txt">
          <iframe class="frame" :src="photo.iframeURL" @load="imageload = true" width="960" height="540" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
        </div>
        <footer class="row justify-center" v-show="getImageLoad">
          <!--h3 class="price">{{0.25}} BTC</h3-->
        </footer>
        
        <!--button class="modal-default-button" @click="$emit('close')">
          OK
        </button-->
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import Loading from "@/components/UI/Loading.vue";
//import Axios from "axios";

export default {
  name: "SingleModal",
  props: {
    insId: null,
    insNum: null,
    contentType: null
  },
  components: {
    Loading,
  },
  data() {
    return {
      photo: [],
      imageload: false
    };
  },
  computed: {
    getImageLoad() {
      return this.imageload;
    }
  },
  async mounted() {
    this.photo = {
      largeImageURL: 'https://ordinals.com/content/' + this.insId,
      iframeURL: 'https://ordinals.com/preview/' + this.insId
    }
    /*
    try {
      const response = await Axios.get(
        `https://ordinals.com/content/${this.$route.params.id}`
      );
      if (response) {
        this.photo = { ...response.data.hits[0] };
      } else {
        this.photo = null;
      }
    } catch (error) {
      console.log(error);
    }*/
  }
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.96);
  display: table;
  transition: opacity 0.3s ease;
}
.single-layout {
  height: 100%;
  width: 100%;
  color: white;
  .loading-container {
    margin: 0;
  }
  .single-image {
    max-width: 100%;
    border-radius: 1rem;
    box-shadow: 0 3px 18px -5px #000;
    background-color: var(--main-background-color);
    max-height: calc(100vh - 200px);
    /*max-height: 400px;*/
    min-height: 400px;
    /*min-height: calc(100vh - 200px);*/
    min-width: 400px;

  }
  .iframe {
    aspect-ratio: 16 / 9;
    height: 100%;
    width: 100%;
  }
}
.image-post {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
header,
footer {
  width: 100%;
  padding: 1rem;
}
header {
  justify-content: space-between;
  .category {
    text-transform: capitalize;
    padding: 5px 12px;
    background-color: var(--blue-color);
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
  }
  .photo-info {
    align-items: center;
    li {
      list-style: none;
      padding: 0 10px;
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
}
footer {
  justify-content: center;
}
@keyframes backgroundChange {
  0% {
    background: var(--main-background-color);
  }
  50% {
    background: var(--four-background-color);
  }
}
</style>
