export default {
  getSignUpForm(state) {
    return state.signUpForm;
  },
  getBuyForm(state) {
    return state.buyForm;
  },
  getCategories(state) {
    return state.categories;
  },
  getShowDummyPsbtQR (state) {
    return state.showDummyPsbtQR;
  },
  getShowBuyPsbtQR (state) {
    return state.showBuyPsbtQR;
  },
  getShowSellPsbtQR (state) {
    return state.showSellPsbtQR;
  },
  getBTCPrice(state) {
    return state.BTCPrice;
  },
  getSellOrder(state) {
    return state.sellOrder;
  },
  getAlbums(state) {
    return state.albums;
  },
  getPhotosTimeline(state) {
    return state.photosTimeline;
  },
  getSearchText(state) {
    return state.searchText;
  },
  getInscription(state) {
    return state.inscription;
  },
  getFilterSection(state) {
    return state.filterSection;
  },
  getBackdrop(state) {
    return state.backdrop;
  },
  getFetchStatus(state) {
    return state.fetchStatus;
  },
  getDarkmode(state) {
    return state.darkmode;
  }
};
