<template>
    <div>
      <div v-if="type=='info'" class="alert info">{{text}}</div>
      <div v-if="type=='error'" class="alert error">{{text}}</div>
    </div>
</template>

<script>
    export default {
        name: "InfoAlert",
        props: {
            text: String,
            type: String
        }
    }
</script>

<style lang="scss" scoped>
.alert {
    padding: 1rem 2rem;
    margin: 1rem 0;
    border-radius: 10px;
    &.info {
        background-color: #ffe600;
        color: #222;
        font-weight: 600;
    };
    &.error {
        background-color: var(--red-color);
        color: #222;
        font-weight: 600;
    }
}
</style>