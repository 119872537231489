import Vue from "vue";
import Vuex from "vuex";
import getters from "./app/getters";
import mutations from "./app/mutations";
import actions from "./app/actions";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    signUpForm: {
      username: "",
      password: "",
    },
    buyForm: {
      payerAddress: "",
      receiverAddress: "",
      invalidPayerAddress: false,
      invalidReceiverAddress: false,
      invalidPayerAddressMsg: "",
      invalidReceiverAddressMsg: "",
      showDummyUtxo: false
    },
    showDummyPsbtQR: false,
    showBuyPsbtQR: false,
    showSellPsbtQR: false,
    BTCPrice: 0,
    sellOrder: null,
    inscription: null,
    albums: [],
    photosTimeline: [],
    searchText: null,
    filterSection: false,
    backdrop: false,
    fetchStatus: false,
    darkmode: false,
    categories: [
      "Latest",
      "Rarest",
      "Priciest",
      "Cheapest",
    ],
  },
  getters,
  mutations,
  actions,
  modules: {
    // const moduleA = {
    //   state: () => ({ ... }),
    //   mutations: { ... },
    //   actions: { ... },
    //   getters: { ... }
    // }
    // const moduleB = {
    //   state: () => ({ ... }),
    //   mutations: { ... },
    //   actions: { ... }
    // }
    // const store = createStore({
    //   modules: {
    //     a: moduleA,
    //     b: moduleB
    //   }
    // })
    // store.state.a // -> `moduleA`'s state
    // store.state.b // -> `moduleB`'s state
  },
});
