<template>
  <Layout center col login>
    <div class="login-card" role="form">
      <div v-show="$store.getters.getFetchStatus" class="col">
        <span v-show="$store.getters.getFetchStatus" class="infinite-status">
            <font-awesome-icon
              class="loading-spinner"
              :icon="['fas', 'fa-spinner']"
              size="lg"
            />Loading...
        </span>
      </div>
      <form
        v-if="!$store.getters.getFetchStatus"
        @submit.prevent="submit()"
        aria-label="Buy"
        spellcheck="false"
        autocomplete="on"
      >
        <div class="col" v-if="!showDummyUtxoQR && !showBuyQR">
          <div class="thumbnail">
            <img v-if="getSellOrder && getSellOrder.content_type.includes('image')" class="thumbnail-image" :src='"https://ordinals.com/content/" + getSellOrder.ins_id'>
            <iframe v-if="getSellOrder && !getSellOrder.content_type.includes('image')" class="thumbnail-iframe" :src='"https://ordinals.com/preview/" + getSellOrder.ins_id' sandbox=allow-scripts scrolling=no loading=lazy>
            </iframe>
          </div>
          <label class="ins-num">#{{getSellOrder ? getSellOrder.ins_num : ""}}</label>
          <h3 class="ins-price-btc">{{getSellOrder ? satToBtc(getSellOrder.price_sat) : ""}} BTC 
            <label class="ins-price-usd"> ${{getSellOrder ? (satToBtc(getSellOrder.price_sat) * getBTCPrice).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }) : 0}} 
            </label>
          </h3>
          <div class="login-inputs">
            <div class="col">
              <label for="payerAddress">Funding address</label>
              <div class="row input-area">
                <!--span class="icon">
                  <font-awesome-icon :icon="['fab', 'btc']" />
                </span-->
                <input
                  type="text"
                  id="payerAddress"
                  accesskey="u"
                  aria-label="Enter BTC address with suficient funds"
                  placeholder="Enter BTC address with suficient funds"
                  autofocus="true"
                  minlength="26"
                  maxlength="62"
                  v-model="payerAddress"
                  @input="updateBuyerAddress"
                  required
                />
              </div>
              <label v-if="invalidPayerAddress" for="payerAddress" class="error">{{invalidPayerAddressMsg}} </label>
            </div>

            <label v-if="showDummyUtxo" for="payerAddress" class="warn">{{"No dummy UTXOs (i.e. value < 1000) found for your funding address, you must create one to execute this order."}}</label>
            
            <div class="col" v-if="!showDummyUtxo">
              <label for="receiverAddress">Receiving address</label>
              <div class="row input-area">
                <!--span class="icon">
                  <font-awesome-icon :icon="['fab', 'btc']" />
                </span-->
                <input
                  type="text"
                  id="receiverAddress"
                  accesskey="p"
                  aria-label="Enter BTC address to receive Inscription"
                  placeholder="Enter BTC address to receive Inscription"
                  minlength="26"
                  maxlength="62"
                  v-model="receiverAddress"
                  @input="validateReceivingAddress"
                  required
                />
              </div>
              <label v-if="invalidReceiverAddress" for="receiverAddress" class="error">{{invalidReceiverAddressMsg}} </label>
            </div>
          </div>
          <button
            v-if="showDummyUtxo"
            type="button"
            class="submit-btn"
            id="dummyUtxo"
            value="Create Dummy UTXO"
            accesskey="d"
            @click="generatePSBTGeneratingDummyUtxos(payerAddress, 1, payerUtxos)"
          > Create Dummy UTXO </button>
          <button v-if="!showDummyUtxo" type="submit" class="submit-btn" aria-label="Buy Now">
            Buy Now
          </button>
          <!--div class="row end">
            <a
              @click.once="log"
              href="/"
              class="forgot-link"
              aria-label="Need Help"
              >Need help?</a
            >
          </div-->
          <!--div class="col question">
            <div class="question-title">
              <span>Are you the owner of this inscription?</span>
            </div>
            <button type="button" class="signup-btn" aria-label="Update Price">
              Update Price
            </button>
          </div-->
        </div>
        <!--Show Dummy Utxo QR block-->
        <div class="col" v-if="showDummyUtxoQR">
          <div class="qr_wrapper">
            <qrcode-vue class="qr_code" :value="psbtDummyUtxos" :size="347" level="L" />
          </div>
          <div class="login-inputs">
            <div class="col">
              <div class="row input-area">
                <textarea :value="psbtDummyUtxos"></textarea>
              </div>
            </div>
          </div>
          <h4 class="miners-fee-btc">Miner's Fee: {{minersFee}} sats 
            <label class="miners-fee-usd"> ${{(satToBtc(minersFee) * getBTCPrice).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}} 
            </label>
          </h4>
          <div>Sign and broadcast this transaction from your BTC wallet to create a dummy UTXO.</div>
          <button class="submit-btn" aria-label="Copy Transaction" @click="copyPSBT()">
            {{buttonCopyText}}
          </button>
          <div class="col question">
            <button type="button" class="signup-btn" aria-label="Download Transaction" @click="downloadPSBT">
              {{buttonDownloadText}}
            </button>
          </div>
          <!--div class="row end">
            <a
              @click.once="log"
              href="/"
              class="forgot-link"
              aria-label="Need Help"
              >Need help?</a
            >
          </div-->
        </div>
        <!--Show Buy QR block-->
        <div class="col" v-if="showBuyQR">
          <div class="qr_wrapper">
            <qrcode-vue class="qr_code" :value="psbtBuyInscription" :size="347" level="L" />
          </div>
          <div class="login-inputs">
            <div class="col">
              <div class="row input-area">
                <textarea :value="psbtBuyInscription"></textarea>
              </div>
            </div>
          </div>
          <h4 class="miners-fee-btc">Miner's Fee: {{minersFee}} sats 
            <label class="miners-fee-usd"> ${{(satToBtc(minersFee) * getBTCPrice).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}} 
            </label>
          </h4>
          <div>Sign and broadcast this transaction from BTC wallet to buy this inscription from the seller.</div>
          <button class="submit-btn" aria-label="Copy Transaction" @click="copyPSBT()">
            {{buttonCopyText}}
          </button>
          <div class="col question">
            <button type="button" class="signup-btn" aria-label="Download Transaction" @click="downloadPSBT">
              {{buttonDownloadText}}
            </button>
          </div>
          <!--div class="row end">
            <a
              @click.once="log"
              href="/"
              class="forgot-link"
              aria-label="Need Help"
              >Need help?</a
            >
          </div-->
        </div>
      </form>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/components/Layout.vue'
import axios from "axios"
//import bitcoin from "bitcoinjs-lib"
import * as bitcoin from 'bitcoinjs-lib'
import 'websocket-polyfill'
import 'stream-browserify'
import QrcodeVue from 'qrcode.vue'
import download from 'downloadjs'

export default {
  name: "LoginView",
  components: {
    Layout,
    QrcodeVue
  },
  data() {
    //return initialState()
    return {
      network: bitcoin.networks.bitcoin,
      txHexByIdCache : [],
      installedWalletName: "",
      price: "",
      dummyUtxoValue: 1_000,
      payerUtxos: [],
      paymentUtxos: [],
      paymentDummyUtxo: "",
      feeLevel: "hourFee", // "fastestFee" || "halfHourFee" || "hourFee" || "economyFee" || "minimumFee"
      minersFee: 0,
      //showInvalidFundingAddress: false,
      //showInvalidReceivingAddress: false,
      //invalidFundingAddressMsg: "",
      //invalidReceivingAddressMsg: "",
      //showDummyUtxo: false,
      psbtDummyUtxos : "",
      psbtBuyInscription: "",
      showBuyQR: false,
      qrCls: 'qrcode',
      sellOrder: {},
      buttonBuyDefaultText: "Buy Now",
      buttonCopyDefaultText: "Copy PSBT",
      buttonCopyClickText: "Copied!",
      buttonDownloadDefaultText: "Download PSBT",
      buttonDownloadClickText: "Downloaded!",
      buttonCopyText: "Copy PSBT",
      buttonDownloadText: "Download PSBT"
    };
  },
  beforeRouteEnter(to, from, next) {
    console.log("to:", to)
    switch (to.name) {
      case "buy":
        console.log("beforeRouteEnter: buy")
        next((vm) => {
          vm.$store.dispatch("fetchOne", to.params.id);
        });
        break;
      default:
        next();
        break;
    }
  },
  beforeRouteLeave(to, from, next) {
    if (from.name == "buy") {
      this.resetBuyForm()
      next()
    }
    next()
  },
  updated() {
    //this.showDummyUtxoQR = false
  },
  computed: {
    getSellOrder() {
      return (this.$store.getters.getSellOrder ? this.$store.getters.getSellOrder[0] : null)
    },
    getBTCPrice() {
      console.log("computed.getBTCPrice:", this.$store.getters.getBTCPrice)
      return this.$store.getters.getBTCPrice;
    },
    isDisabled() {
      return !this.payerAddress || !this.receiverAddress || this.invalidPayerAddress || this.invalidReceiverAddress
    },
    showDummyUtxoQR () {
      return this.$store.getters.getShowDummyPsbtQR
    },
    payerAddress: {
      get () {
        return this.$store.getters.getBuyForm.payerAddress
      },
      set(value) {
        this.$store.commit('setBuyFormPayerAddress', value)
      }
    },
    receiverAddress: {
      get () {
        return this.$store.getters.getBuyForm.receiverAddress
      },
      set(value) {
        this.$store.commit('setBuyFormReceiverAddress', value)
      }
    },
    invalidPayerAddress: {
      get () {
        return this.$store.getters.getBuyForm.invalidPayerAddress
      },
      set(value) {
        this.$store.commit('setBuyFormInvalidPayerAddress', value)
      }
    },
    invalidReceiverAddress: {
      get () {
        return this.$store.getters.getBuyForm.invalidReceiverAddress
      },
      set(value) {
        this.$store.commit('setBuyFormInvalidReceiverAddress', value)
      }
    },
    invalidPayerAddressMsg: {
      get () {
        return this.$store.getters.getBuyForm.invalidPayerAddressMsg
      },
      set(value) {
        this.$store.commit('setBuyFormInvalidPayerAddressMsg', value)
      }
    },
    invalidReceiverAddressMsg: {
      get () {
        return this.$store.getters.getBuyForm.invalidPayerAddressMsg
      },
      set(value) {
        this.$store.commit('setBuyFormInvalidReceiverAddressMsg', value)
      }
    },
    showDummyUtxo: {
      get () {
        return this.$store.getters.getBuyForm.showDummyUtxo
      },
      set(value) {
        this.$store.commit('setBuyFormShowDummyUtxo', value)
      }
    }
  },
  methods: {
    async submit() {
      console.log("submit BUY")
      this.psbtBuyInscription = await this.generatePSBTBuyingInscription(this.payerAddress, this.receiverAddress, this.getSellOrder.price_sat, this.paymentUtxos, this.paymentDummyUtxo)
      this.showBuyQR = true
      console.log("psbtBuyIns$$$$$$", this.psbtBuyInscription)
    },
    resetBuyForm() {
      this.psbtBuyInscription = ""
      this.showBuyQR = false
      this.showDummyUtxo =false
      this.invalidReceiverAddressMsg = ""
      this.invalidPayerAddressMsg = ""
      this.invalidPayerAddress = false
      this.invalidReceiverAddress = false
      this.payerAddress = ""
      this.receiverAddress = ""
      this.psbtDummyUtxos = ""
    },
    async copyPSBT() {
      try {
        if(this.showBuyQR) {
          await navigator.clipboard.writeText(this.psbtBuyInscription)
        } else if (this.showDummyUtxoQR) {
          await navigator.clipboard.writeText(this.psbtDummyUtxos)
        }
        this.buttonCopyText = this.buttonCopyClickText
        setTimeout(function () {
            this.buttonCopyText = this.buttonCopyDefaultText
        }.bind(this), 1000);
      } catch(e) {
        console.error(e)
      }
    },
    async downloadPSBT() {
      try {
        if(this.showBuyQR) {
          await download(this.psbtBuyInscription, "bitnftx-buy-" + this.getSellOrder.ins_num + ".psbt", "text/plain")
        } else if (this.showDummyUtxoQR) {
          await download(this.psbtDummyUtxos, "create-dummy-utxo.psbt", "text/plain")
        }
        //await download(this.unsignedPSBT, "bitnftx-unsigned-sellorder-" + this.getInscription.ins_num + ".psbt", "text/plain")
        this.buttonDownloadText = this.buttonDownloadClickText
        setTimeout(function () {
            this.buttonDownloadText = this.buttonDownloadDefaultText
        }.bind(this), 1000);
      } catch(e) {
        console.error(e)
      }
    },
    log() {
      console.log("stop")
    },
    satToBtc(sat) {
      return Number(sat) / Math.pow(10, 8)
    },
    btcToSat(btc) {
      return Math.floor(Number(btc) * Math.pow(10, 8))
    },
    calculateFee(vins, vouts, recommendedFeeRate, includeChangeOutput = true) {
      const baseTxSize = 10
      const inSize = 180
      const outSize = 34
      const txSize = baseTxSize + (vins * inSize) + (vouts * outSize) + (includeChangeOutput * outSize)
      const fee = txSize * recommendedFeeRate
      this.minersFee = fee
      return fee
    },
    async getContent(id) {
      return await axios.get("https://ordinals.com/content/" + id)
      .then(response => response.data)
    },
    async getInscriptionDataById(inscriptionId, verifyIsInscriptionNumber) {
      const html = await axios.get("https://ordinals.com" + "/inscription/" + inscriptionId)
          .then(response => response.data)

      const data = [...html.matchAll(/<dt>(.*?)<\/dt>\s*<dd.*?>(.*?)<\/dd>/gm)]
          .map(x => { x[2] = x[2].replace(/<.*?>/gm, ''); return x })
          .reduce((a, b) => { return { ...a, [b[1]]: b[2] } }, {});

      const error = `Inscription ${verifyIsInscriptionNumber || inscriptionId} not found (maybe you're on signet and looking for a mainnet inscription or vice versa)`
      try {
          data.number = html.match(/<h1>Inscription (\d*)<\/h1>/)[1]
      } catch { throw new Error(error) }
      if (verifyIsInscriptionNumber && String(data.number) != String(verifyIsInscriptionNumber)) {
          throw new Error(error)
      }

      return data
    },
    async getAddressUtxos(payerAddress) {
      return await axios.get("https://mempool.space/api/address/" + payerAddress + "/utxo")
      .then(response => response.data)
    },
    async recommendedFeeRate () {
      const res = await axios.get("https://mempool.space/api/v1/fees/recommended")
            .then(response => response.data) 
      console.log("mempool-fees: ", res)
      
      return await axios.get("https://mempool.space/api/v1/fees/recommended")
            .then(response => response.data[this.feeLevel])
    },
    async getTxHexById(txId) {
      if (!this.txHexByIdCache[txId]) {
        this.txHexByIdCache[txId] = await axios.get("https://mempool.space/api/tx/" + txId + "/hex")
          .then(response => response.data)
      }

      return this.txHexByIdCache[txId]
    },
    async doesUtxoContainInscription(utxo) {
      const html = await axios.get("https://ordinals.com/output/" + utxo.txid + ":" + utxo.vout)
        .then(response => response.data)
        .catch(error => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser 
            // and an instance of http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          throw error
        })

      return html.match(/class=thumbnails/) !== null
    },
    async selectUtxos(utxos, amount, vins, vouts, recommendedFeeRate) {
      const selectedUtxos = []
      let selectedAmount = 0

      // Sort descending by value, and filter out dummy utxos
      utxos = utxos.filter(x => x.value > this.dummyUtxoValue).sort((a, b) => b.value - a.value)

      for (const utxo of utxos) {
        // Never spend a utxo that contains an inscription for cardinal purposes
        if (await this.doesUtxoContainInscription(utxo)) {
            continue
        }
        selectedUtxos.push(utxo)
        selectedAmount += utxo.value

        if (selectedAmount >= amount + this.dummyUtxoValue + this.calculateFee(vins + selectedUtxos.length, vouts, recommendedFeeRate)) {
            break
        }
      }

      if (selectedAmount < amount) {
          //throw new Error(`Not enough cardinal spendable funds.
            throw `Insuficient cardinal spendable funds.
            Address has:  ${this.satToBtc(selectedAmount)} BTC
            Needed:       ${this.satToBtc(amount)} BTC
            UTXOs:        ${utxos.map(x => `${x.txid}:${x.vout}`).join("\n")}`
      }

      return selectedUtxos
    },

    async generatePSBTGeneratingDummyUtxos(payerAddress, numberOfDummyUtxosToCreate, payerUtxos) {
      console.log(payerAddress, numberOfDummyUtxosToCreate, payerUtxos)
      const network = this.network
      const psbt = new bitcoin.Psbt({ network });
      let totalValue = 0

      for (const utxo of payerUtxos) {
        const tx = bitcoin.Transaction.fromHex(await this.getTxHexById(utxo.txid))
        for (const output in tx.outs) {
          try { tx.setWitness(parseInt(output), []) } catch { "do-nothing-eslint-no-empty" }
        }

        if (this.installedWalletName === 'OrdinalSafe'){
          psbt.addInput({
            hash: utxo.txid,
            index: utxo.vout,
            // nonWitnessUtxo: tx.toBuffer(),
            witnessUtxo: tx.outs[utxo.vout],
          });
        } else {
          psbt.addInput({
            hash: utxo.txid,
            index: utxo.vout,
            nonWitnessUtxo: tx.toBuffer(),
            // witnessUtxo: tx.outs[utxo.vout],
          });
        }

        totalValue += utxo.value
      }
      for (let i = 0; i < numberOfDummyUtxosToCreate; i++) {
        psbt.addOutput({
          address: payerAddress,
          value: this.dummyUtxoValue,
        });
      }
      const recommendedFeeRate = await this.recommendedFeeRate()
      console.log("recommendedFeeRate", recommendedFeeRate)
      const fee = this.calculateFee(psbt.txInputs.length, psbt.txOutputs.length, await this.recommendedFeeRate())
      // Change utxo
      psbt.addOutput({
        address: payerAddress,
        value: totalValue - (numberOfDummyUtxosToCreate * this.dummyUtxoValue) - fee,
      });
      this.psbtDummyUtxos = psbt.toBase64()
      this.showDummyUtxo = false
      this.$store.commit("setShowDummyPsbtQR", true)
      this.$store.commit("setPayerAddress", this.payerAddress)
      this.payerAddress = ""
    },

    async generatePSBTBuyingInscription(payerAddress, receiverAddress, price, paymentUtxos, dummyUtxo) {
      const network = this.network
      const psbt = new bitcoin.Psbt({ network });
      let totalValue = 0
      let totalPaymentValue = 0

      // Add dummy utxo input
      const tx = bitcoin.Transaction.fromHex(await this.getTxHexById(dummyUtxo.txid))
      for (const output in tx.outs) {
        try { tx.setWitness(parseInt(output), []) } catch(e) { console.error(e) }
      }

      if(this.installedWalletName === 'OrdinalSafe'){
        psbt.addInput({
          hash: dummyUtxo.txid,
          index: dummyUtxo.vout,
          // nonWitnessUtxo: tx.toBuffer(),
          witnessUtxo: tx.outs[dummyUtxo.vout],
        });
      } else {
        psbt.addInput({
          hash: dummyUtxo.txid,
          index: dummyUtxo.vout,
          nonWitnessUtxo: tx.toBuffer(),
          // witnessUtxo: tx.outs[dummyUtxo.vout],
        });
      }
      const inscription = await this.getInscriptionDataById(this.getSellOrder.ins_id, this.getSellOrder.ins_num)
      // Add inscription output
      psbt.addOutput({
        address: receiverAddress,
        value: dummyUtxo.value + Number(inscription['output value']),
      });
      const sellerSignedPsbt = bitcoin.Psbt.fromBase64(this.getSellOrder.content, { network })
      // Add payer signed input
      psbt.addInput({
        ...sellerSignedPsbt.data.globalMap.unsignedTx.tx.ins[0],
        ...sellerSignedPsbt.data.inputs[0]
      })
      // Add payer output
      psbt.addOutput({
        ...sellerSignedPsbt.data.globalMap.unsignedTx.tx.outs[0],
      })
      // Add payment utxo inputs
      for (const utxo of paymentUtxos) {
        const tx = bitcoin.Transaction.fromHex(await this.getTxHexById(utxo.txid))
        for (const output in tx.outs) {
          try { tx.setWitness(parseInt(output), []) } catch (e) { console.log(e) }
        }
        if (this.installedWalletName === 'OrdinalSafe'){
          psbt.addInput({
            hash: utxo.txid,
            index: utxo.vout,
            // nonWitnessUtxo: tx.toBuffer(),
            witnessUtxo: tx.outs[utxo.vout],
          });
        } else {
          psbt.addInput({
            hash: utxo.txid,
            index: utxo.vout,
            nonWitnessUtxo: tx.toBuffer(),
            // witnessUtxo: tx.outs[utxo.vout],
          });
        }
        totalValue += utxo.value
        totalPaymentValue += utxo.value
      }
      // Create a new dummy utxo output for the next purchase
      psbt.addOutput({
        address: payerAddress,
        value: this.dummyUtxoValue,
      })
      const fee = this.calculateFee(psbt.txInputs.length, psbt.txOutputs.length, await this.recommendedFeeRate())
      const changeValue = totalValue - dummyUtxo.value - price - fee
      if (changeValue < 0) {
        this.invalidPayerAddressMsg = `Insuficient Funds. Required ${this.satToBtc(totalValue - changeValue)} BTC`
        this.invalidPayerAddress = true
          throw `Your wallet address doesn't have enough funds to buy this inscription.
Price:          ${this.satToBtc(price)} BTC
Fees:       ${this.satToBtc(fee + this.dummyUtxoValue)} BTC
You have:   ${this.satToBtc(totalPaymentValue)} BTC
Required:   ${this.satToBtc(totalValue - changeValue)} BTC
Missing:     ${this.satToBtc(-changeValue)} BTC`
      }
      // Change utxo
      psbt.addOutput({
        address: payerAddress,
        value: changeValue,
      });
      console.log(psbt.toBase64())
      return psbt.toBase64();
    },
    
    async updateBuyerAddress () {
      console.log("updateBuyerAddress:payerAddress", this.payerAddress)
      this.invalidPayerAddress = false
      if (this.payerAddress && this.payerAddress.trim() !== "") {

        /*if (this.receiverAddress.trim() == "") {
          this.receiverAddress = this.payerAddress
        }*/
        this.invalidPayerAddress = true
        this.invalidPayerAddressMsg = "Validating Address..."
        
        this.showDummyUtxo = false
        //this.showDummyUtxoQR = false
        let dummyUtxo
        try{
          this.payerUtxos = await this.getAddressUtxos(this.payerAddress)
        } catch(e){
          console.log("Invalid Bitcoin Address")
          this.invalidPayerAddressMsg = "Invalid Bitcoin Address"
          this.invalidPayerAddress = true
          //console.log(e)
          return
        } finally {
          "do-nothing-eslint-no-empty"
        }

        const potentialDummyUtxos = this.payerUtxos.filter(utxo => utxo.value <= this.dummyUtxoValue)
        dummyUtxo = undefined
        this.invalidPayerAddress = true
        this.invalidPayerAddressMsg = "Checking if utxo contains inscription..."

        try {
          for (const potentialDummyUtxo of potentialDummyUtxos) {
            if (!(await this.doesUtxoContainInscription(potentialDummyUtxo))) {
              this.showDummyUtxo = false
              dummyUtxo = potentialDummyUtxo
              break
            }
          }
        } catch(e) {
          this.invalidPayerAddressMsg = e.message
          this.invalidPayerAddress = true
          return
        }
        
        let minimumValueRequired
        let vins
        let vouts
        const numberOfDummyUtxosToCreate = 1

        if (!dummyUtxo) {
          this.showDummyUtxo = true
          console.log("showDummyUtxoBlock")
          minimumValueRequired = (numberOfDummyUtxosToCreate * this.dummyUtxoValue)
          vins = 0
          vouts = numberOfDummyUtxosToCreate
        } else {
          this.paymentDummyUtxo = dummyUtxo
          this.showDummyUtxo = false
          minimumValueRequired = this.price + (numberOfDummyUtxosToCreate * this.dummyUtxoValue)
          vins = 1
          vouts = 2 + numberOfDummyUtxosToCreate
        }

        try {
          this.paymentUtxos = await this.selectUtxos(this.payerUtxos, minimumValueRequired, vins, vouts, await this.recommendedFeeRate())
          this.invalidPayerAddress = false
        } catch (e) {
            this.paymentUtxos = undefined
            console.error(e)
            //document.getElementById('payerAddress').classList.add('is-invalid')
            //document.getElementById('btnBuyInscription').disabled = true
            this.invalidPayerAddressMsg = e
            this.invalidPayerAddress = true
            this.showDummyUtxo = false
            return
        }
        console.log("payerUtxos", this.payerUtxos)
        console.log("paymentUtxos", this.paymentUtxos)
      
      } else {
        this.showDummyUtxo = false
        //this.showDummyUtxoQR = false
        console.error("Funding address can't be empty")
      }
    },
    async validateReceivingAddress () {
      
      console.log("Validate Receiving Address")
      if (this.receiverAddress && this.receiverAddress.trim() !== "") {
        this.invalidReceiverAddress = true
        this.invalidReceiverAddressMsg = "Validating Address..."
        let receiverUtxo
        try {
          receiverUtxo = await this.getAddressUtxos(this.receiverAddress)
          this.invalidReceiverAddress = false
        } catch(e){
          console.log("Invalid Bitcoin Address")
          this.invalidReceiverAddressMsg = "Invalid Bitcoin Address"
          this.invalidReceiverAddress = true
          //console.log(e)
          return
        } finally {
          "do-nothing-eslint-no-empty"
        }
        if(receiverUtxo.length > 0)
        {
          console.log("receiverAddress already contains funds")
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--first-background-color);
  display: table;
  transition: opacity 0.3s ease;
}
.login-card {
  background: var(--second-background-color);
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 100%;
  /*max-width: 380px;*/
  max-width: 412px;
  animation: fromTopWithFade 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  @media screen and (min-width: 600px) {
    padding: 5rem 2rem;
    box-shadow: var(--login-form-card-shadow);
  }
  qr_code {
    align-self: center;
  }
  h3 {
    text-align: left;
    font-weight: 800;
  }
  h2 {
    text-align: center;
    font-weight: 800;
  }
  label {
    font-size: 12px;
    font-weight: 600;
    color: var(--login-form-label-color);
    margin-bottom: 3px;
  }
  .input-area {
    position: relative;
    input {
      flex: 1;
      padding: 1rem 1rem 1rem 3rem;
      border: none;
      outline: 0;
      transition: all 0.2s ease;
      background: var(--login-form-input-background-color);
      border-bottom: 2px solid transparent;
      border-radius: 0.5rem;
      &:focus {
        border-color: #5e60d4;
        background: var(--login-form-input-focus-background-color);
      }
    }
    textarea {
      flex: 1;
      /*padding: 1rem 1rem 1rem 3rem;*/
      border: none;
      outline: 0;
      transition: all 0.2s ease;
      background: var(--login-form-input-background-color);
      border-bottom: 2px solid transparent;
      border-radius: 0.5rem;
      &:focus {
        border-color: #5e60d4;
        background: var(--login-form-input-focus-background-color);
      }
    }
    .icon {
      display: flex;
      align-items: center;
      position: absolute;
      left: 1rem;
      height: 100%;
      margin-top: -1px;
    }
  }
  .thumbnail {
    border-radius: 5px;
    /*display: flex;*/
    overflow: hidden;
    background-color: var(--third-background-color);
    /*height: 316px;
    width: 316px;*/
    height: 348px;
    width: 348px;
    /*animation: backgroundChange 1.8s linear infinite;*/
    &:hover {
      .thumbnail-image1 {
        cursor: pointer;
        filter: saturate(1.6);
        transition: all 0.3s ease;
        transform: scale(1.2) /*translate(10px, 10px);*/
        /*position:absolute; left:50px; transform: scale(1.3)*/
      }
      .txt {
        filter: saturate(1.6);
        transition: all 0.3s ease;
        transform: scale(1.2) /*translate(10px, 10px);*/
        /*position:absolute; left:50px; transform: scale(1.3)*/
      }
    }
    .thumbnail-image {
      /*border-radius: 5px;*/
      align-self: center;
      object-fit: contain;
      /*height: 316px;
      width: 316px;*/
      height: 348px;
      width: 348px;
    }
    .thumbnail-iframe {
      border-radius: 5px;
      /*height: 316px;
      width: 316px;*/
      height: 348px;
      width: 348px;
      min-height: 348px;
      max-height: 348px;
      border: 0;
    }
  }
  .error {
  color: #D8000C;
  }
  .error:hover {
    
  }
  .warn {
    color: #9F6000;
  }
  .warn:hover {
    
  }
  .ins-num {
    cursor: auto;
    font-weight: bolder;
    font-size: 16px;
  }
  .ins-price-btc {
    color: var(--login-form-label-color);
  }
  .ins-price-usd {
    cursor: auto;
    padding: 0.5rem;
    font-size: 17px;
    color: var(--login-form-label-color);
  }
  .miners-fee-btc {
    color: var(--login-form-label-color);
  }
  .miners-fee-usd {
    cursor: auto;
    padding: 0.25rem;
    font-size: 14px;
    color: var(--login-form-label-color);
  }
  .qr_wrapper {
    align-self: center;
  }
  .col {
    margin-bottom: 1rem;
  }
  .forgot-link {
    font-size: 12px;
    font-weight: 600;
    color: var(--login-form-link-color);
  }
  .submit-btn {
    margin: 1.5rem 0;
    padding: 1rem;
    /*background-image: linear-gradient(to right, #00F260, #9798e6, #e23ff5);*/
    background-color: var(--green-color);
    background-size: 300%;
    background-position: center;
    color: white;
    font-weight: 600;
    text-shadow: 0 1px 1px #00000020;
    &:hover {
      box-shadow: 0 2px 12px -8px #000;
      background-position: 0;
      border-radius: 30px;
    }
    &:focus {
      box-shadow: none;
      transform: translateY(1px);
    }
  }
  .question {
    .question-title {
      position: relative;
      text-align: center;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 100%;
        height: 0;
        border-bottom: 2px dotted var(--second-background-color);
      }
      span {
        position: relative;
        font-size: 14px;
        color: #6d6d6d;
        z-index: 3;
        font-weight: 600;
        background-color: var(--second-background-color);
        padding: 0 1rem;
      }
    }
    .signup-btn {
      margin-top: 1rem;
      padding: 1rem;
      font-weight: 600;
      font-size: 15px;
      color: var(--button-color);
      &:hover {
        background: var(--button-background-hover-color);
        border-radius: 30px;
      }
    }
  }
}
</style>
