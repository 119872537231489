<template>
  <footer class="col align-center justify-end">
    <div> Zero-Fee Trading </div>
    <div> Trustless and Decentralized <a href="https://docs.ordinals.com/">Ordinals</a> Marketplace</div>
    <div>Runs on <a href="https://nostr.com/">Nostr</a> <a href="https://openordex.org/">OpenOrdex</a> <a href="https://github.com/orenyomtov/openordex/blob/6b087eab9bfe78fbbdd00f9026093c141a527eaa/js/app.js#L12">Protocol</a>
    </div>
    <font-awesome-icon :icon="['fab', 'bitcoin']" size="2x"/>
    <!--a href="#" target="blank"><font-awesome-icon :icon="['fab', 'bitcoin']" size="2x"></a-->
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
  props: {},
};
</script>

<style lang="scss" scoped>
footer {
  font-size: 14px;
  font-weight: 300;
  margin: 2rem 0;
  flex: 1 !important;
  opacity: 0.7;
  transition: all 0.1s ease;
  &:hover {
    opacity: 1;
  }
  span {
    color: red;
    padding: 0 6px;
    font-size: 18px;
  }
  svg {
    margin-top: 10px;
  }
  a {
    color: inherit;
    transition: all 0.1s ease;
    &:hover {
      filter: drop-shadow(0 2px 10px var(--button-color));
    }
  }
}
</style>
