export default {
  setUsername(state, payload) {
    state.signUpForm = {
      ...state.signUpForm,
      username: payload,
    };
  },
  setPassword(state, payload) {
    state.signUpForm = {
      ...state.signUpForm,
      password: payload,
    };
  },
  resetBuyForm(state) {
    state.buyForm = {
      payerAddress: "",
      receiverAddress: "",
      invalidPayerAddress: false,
      invalidReceiverAddress: false,
      invalidPayerAddressMsg: "",
      invalidReceiverAddressMsg: "",
      showDummyUtxo: false
    }
  },
  setBuyFormPayerAddress(state, payload) {
    state.buyForm = {
      ...state.buyForm,
      payerAddress: payload,
    };
  },
  setBuyFormReceiverAddress(state, payload) {
    state.buyForm = {
      ...state.buyForm,
      receiverAddress: payload,
    };
  },
  setBuyFormInvalidPayerAddress(state, payload) {
    state.buyForm = {
      ...state.buyForm,
      invalidPayerAddress: payload,
    };
  },
  setBuyFormInvalidReceiverAddress(state, payload) {
    state.buyForm = {
      ...state.buyForm,
      invalidReceiverAddress: payload,
    };
  },
  setBuyFormInvalidPayerAddressMsg(state, payload) {
    state.buyForm = {
      ...state.buyForm,
      invalidPayerAddressMsg: payload,
    };
  },
  setBuyFormInvalidReceiverAddressMsg(state, payload) {
    state.buyForm = {
      ...state.buyForm,
      invalidReceiverAddressMsg: payload,
    };
  },
  setBuyFormShowDummyUtxo(state, payload) {
    state.buyForm = {
      ...state.buyForm,
      showDummyUtxo: payload,
    };
  },
  setShowDummyPsbtQR (state, payload) {
    state.showDummyPsbtQR = payload;
  },
  setShowBuyPsbtQR (state, payload) {
    state.showBuyPsbtQR = payload;
  },
  setShowSellPsbtQR (state, payload) {
    state.showSellPsbtQR = payload;
  },
  setBTCPrice(state, payload) {
    state.BTCPrice = payload;
  },
  setSellOrder(state, payload) {
    state.sellOrder = payload;
  },
  setAlbums(state, payload) {
    state.albums = payload;
  },
  setPhotosTimeline(state, payload) {
    state.photosTimeline = payload;
  },
  morePhotosTimeline(state, payload) {
    state.photosTimeline = [...state.photosTimeline, ...payload];
  },
  setSearchText(state, payload) {
    state.searchText = payload;
  },
  setInscription(state, payload) {
    state.inscription = payload;
  },
  toggleFilterSection(state) {
    state.filterSection = !state.filterSection;
  },
  toggleBackdrop(state) {
    state.backdrop = !state.backdrop;
  },
  setFetchStatus(state, payload) {
    state.fetchStatus = payload
  },
  darkmode(state, payload) {
    state.darkmode = payload
  }
};
